export async function CityFacts(city) {
    try {
        const cityFactsResponse = await fetch(`https://en.wikipedia.org/api/rest_v1/page/summary/`+city);
        const cityFactsData = await cityFactsResponse.json();
        const cityFacts=cityFactsData.extract;
        let longString = String(cityFacts);
        let wordsArray = longString.split(' ');
        let shortenedString = wordsArray.slice(0, 60).join(' ');
        return {shortenedString};
      } catch (error) {
        console.error("Error fetching the IP address:", error);
        return {shortenedString:"" };
      }
    };