

const PlaceCard = ({
  displayName,
  photoUrl,
  lat,
  lng,
  reviews,
  place,
  formattedAddress,
}) => {


  // console.log(place)


  let longString = reviews;
  longString = String(longString);
  let wordsArray = longString.split(' ');
  let shortenedString = wordsArray.slice(0, 60).join(' ') + '...';

  return (
    <div
      onClick={() =>
        (window.location.href =
          "https://www.google.com/maps?q=" + lat + "," + lng)
      }
      className="m-5 flex flex-col md:flex-row hover:scale-105 transition-transform duration-300 hover:bg-white hover:cursor-pointer rounded-lg bg-gray-100 p-2 md:p-4 shadow-2xl"
    >
      <div className="rounded-md w-full h-[30vh] md:h-[250px] lg:h-[300px]">
        <img
          className="rounded-t-md md:rounded-l-md w-full h-full object-cover"
          src={photoUrl}
        />
      </div>
      <div className="mt-4 md:mt-0 md:ml-4 flex flex-col justify-between w-full">
        <h1 className="text-lg md:text-2xl lg:text-3xl font-bold m-2">{displayName}</h1>
        <div className="bg-white rounded-b-md md:rounded-l-none shadow-xl p-4 md:p-6 lg:p-8">
          <h2 className="text-sm md:text-base lg:text-lg">{shortenedString}</h2>
          {/* <ReviewSummary reviews={reviews} formattedAddress={formattedAddress} displayName={displayName}/> */}
        </div>
      </div>
    </div>
  );
};

export default PlaceCard;
