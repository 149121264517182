import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import PlaceContext from "../utils/PlaceContext";
import jsonData from "../utils/PlaceMapping.json";
import { CityFacts } from "../utils/CityFacts";


const UriParamsPlace = () => {
  const [timeNow, setTimeNow] = useState(new Date().toLocaleTimeString());

  const { placeidparam } = useParams();
  const placeIdParamMapping = jsonData[placeidparam];
  // console.log(placeIdParamMapping)

  const {
    setPlaceIdCommon,
    setPlaceLatCommon,
    setPlaceLngCommon,
    setDisplayNameCommon,
    setCityFacts
  } = useContext(PlaceContext);

  useEffect(() => {
    const fetchCityFacts = async (cityName) => {
      const data = await CityFacts(cityName);
      const { shortenedString } = data;
      setCityFacts(shortenedString);
    };

    
  const fetchPlaceByPlaceId = async () => {
    const { PlacesService, PlacesServiceStatus } =
      await google.maps.importLibrary("places");

    const map = new google.maps.Map(document.createElement("div"));

    const service = new PlacesService(map);

    const request = {
      placeId: placeIdParamMapping,
      fields: ["name", "formatted_address", "geometry"],
    };

    service.getDetails(request, (place, status) => {
      if (status === PlacesServiceStatus.OK) {
        const displayName = place.name;
        const formattedAddress = place.formatted_address;
        const location = place.geometry.location.toJSON();

        setPlaceIdCommon(jsonData.placeidparam);
        setPlaceLatCommon(location.lat);
        setPlaceLngCommon(location.lng);
        setDisplayNameCommon(displayName);
        fetchCityFacts(displayName);
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

    fetchPlaceByPlaceId();
    updateTime();
  }, [timeNow]);

  const updateTime = () => {
    setTimeNow(new Date().toLocaleTimeString());
  };

};
export default UriParamsPlace;
