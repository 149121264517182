import React from "react";

const ContactUs = () => {
  return (
    <div className="flex items-center justify-center w-full p-2 md:p-4">
      <div className="rounded-xl shadow-2xl w-full md:w-10/12 lg:w-8/12 bg-gray-150">
        <div className="flex flex-col md:flex-row">
          <div className="m-10">
            <h1 className="text-3xl font-bold text-gray-800 mb-10 px-20">Contact Us</h1>
            <p className="text-gray-600 mb-10 text-2xl px-20">
            We’d love to hear from you! Whether you have a question, need assistance, or simply want to share your thoughts, we’re always here to listen. Your feedback is incredibly valuable to us, as it helps shape and improve our platform to better meet your needs and enhance your travel experiences.
            </p>
            <p className="text-gray-600 mb-10 text-2xl px-20">
            Please don’t hesitate to reach out with any inquiries, suggestions, or feedback. Our dedicated team is eager to assist you and ensure your journey with us is seamless and enjoyable.
            </p>
            <p className="text-gray-600 mb-4 text-2xl px-20">
            You can connect with us through the following channels, and we’ll do our best to respond promptly!
            </p>
          </div>
        </div>

        <div className="p-10">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4 px-20">
            Get In Touch
          </h2>
          <ul className="list-disc list-inside text-gray-600 mb-4 text-xl px-20">
            <li>📧 Email: support@wondersofwander.com</li>
            <li>📸 Instagram:<a href="https://www.instagram.com/vishnu_350cc/" target="_blank"  className="text-blue-700">@Author_Instagram</a></li>
            <li>📸 YouTube: <a href="https://www.youtube.com/@vishnuekkar" target="_blank"  className="text-blue-700">@Author_Youtube</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
