import React, { createContext, useEffect, useState } from "react";
import { CurrentLocData } from "../utils/CurrentLocData";

const PlaceContext = createContext();

export const PlaceProvider = ({ children }) => {
  useEffect(() => {
    const fetchLocationData = async () => {
      const data = await CurrentLocData();
      const { city, latitude, longitude, shortenedString } = data;
      setDisplayNameCommon(city);
      setPlaceLatCommon(latitude);
      setPlaceLngCommon(longitude);
      setCityFacts(shortenedString);
    };

    fetchLocationData();
  }, []);

  const [placeIdCommon, setPlaceIdCommon] = useState("");
  const [placeLatCommon, setPlaceLatCommon] = useState("");
  const [placeLngCommon, setPlaceLngCommon] = useState("");
  const [displayNameCommon, setDisplayNameCommon] = useState("");
  const [cityFacts, setCityFacts] = useState("");

  return (
    <PlaceContext.Provider
      value={{
        placeIdCommon,
        setPlaceIdCommon,
        placeLatCommon,
        setPlaceLatCommon,
        placeLngCommon,
        setPlaceLngCommon,
        displayNameCommon,
        setDisplayNameCommon,
        cityFacts,
        setCityFacts,
      }}
    >
      {children}
    </PlaceContext.Provider>
  );
};

export default PlaceContext;
