export async function CurrentLocData() {
    try {
      const response = await fetch("https://ipinfo.io/?token=a4e6b5b50490d5");
      const data = await response.json();
      const [latitude, longitude] = data.loc.split(",");
      const city = data.city;

      const cityFactsResponse = await fetch(`https://en.wikipedia.org/api/rest_v1/page/summary/`+city);
      const cityFactsData = await cityFactsResponse.json();
      const cityFacts=cityFactsData.extract;
      let longString = String(cityFacts);
      let wordsArray = longString.split(' ');
      let shortenedString = wordsArray.slice(0, 60).join(' ');


      return { city, latitude, longitude, shortenedString};
    } catch (error) {
      console.error("Error fetching the IP address:", error);
      return { city: null, latitude: null, longitude: null, shortenedString:"" }; // Handle error by returning null values
    }
  }