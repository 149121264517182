import React, { useEffect, useState, useContext } from "react";
import PlaceContext from "../utils/PlaceContext";
import { CityFacts } from "../utils/CityFacts";

const MapAutocomplete = () => {
  const [selectedPlace, setSelectedPlace] = useState(null);

  const {
    setPlaceIdCommon,
    setPlaceLatCommon,
    setPlaceLngCommon,
    setDisplayNameCommon,
    setCityFacts,
  } = useContext(PlaceContext);

  useEffect(() => {
    const fetchCityFacts = async (cityName) => {
      const data = await CityFacts(cityName);
      const { shortenedString } = data;
      setCityFacts(shortenedString);
    };

    const initMap = async () => {
      await google.maps.importLibrary("places");
      const { Map, InfoWindow } = await google.maps.importLibrary("maps");

      const placeAutocomplete =
        new google.maps.places.PlaceAutocompleteElement();
      document
        .getElementById("autocomplete-container")
        .appendChild(placeAutocomplete);

      placeAutocomplete.addEventListener(
        "gmp-placeselect",
        async ({ place }) => {
          await place.fetchFields({
            fields: ["displayName", "formattedAddress", "location"],
          });

          const placeData = place.toJSON();
          const {
            id,
            location: { lat, lng },
            displayName,
          } = placeData;
          setSelectedPlace(placeData);
          setPlaceIdCommon(id);
          setPlaceLatCommon(lat);
          setPlaceLngCommon(lng);
          setDisplayNameCommon(displayName);
          fetchCityFacts(displayName);
        }
      );
    };

    initMap();

    return () => {
      const container = document.getElementById("autocomplete-container");
      if (container) {
        container.innerHTML = "";
      }
    };
  }, []);

  return (
    <div className=" flex items-center justify-center">
      <div
        className="w-8/12 flex items-center justify-center py-4 px-11"
        id="autocomplete-container"
      ></div>
    </div>
  );
};

export default MapAutocomplete;

// https://developers.google.com/maps/documentation/javascript/place-details
