import React from "react";
import ReactDOM from "react-dom/client";
import Header from "./components/Header";
import MapAutocomplete from "./components/MapAutocomplete";
import { PlaceProvider } from "./utils/PlaceContext";
import BlogTemplate from "./components/BlogTemplate";
import Footer from "./components/Footer";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Error from "./components/Error";
import Body from "./components/Body";
import UriParamsPlace from "./components/UriParamsPlace";
import Policies from "./components/Policies";
import ContactUs from "./components/ContactUS";


const AppLayout = () => {
  return (
    <div>
      <PlaceProvider>
        <Header />
        <Outlet/>
        <Footer />
      </PlaceProvider>
    </div>
  );
};

const appRouter =createBrowserRouter([
  {
    path:"",
    element:<AppLayout/>,
    children:[
      {
        path:"",
        element:<Body/>,
      },
      {
        path:"/about",
        element:<AboutUs/>
      },
      {
        path:"/contact",
        element:<ContactUs/>
      },
      {
        path:"/policies",
        element:<Policies/>
      },
      {
        path:"/place/:placeidparam",
        element:<Body/>
      },


    ],
    errorElement:<Error/>,
  },


])

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
