import React, { useEffect, useRef, useState } from "react";
import PlaceCard from "./PlaceCard";
import PlaceCheckBoxes from "./PlaceCheckBoxes";

const GoogleMapComponent = ({
  latitude,
  longitude,
  displayName,
  cityFacts,
}) => {
  const mapRef = useRef(null);
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    let map;

    const initMap = async () => {
      // Load the necessary libraries
      const { Map, InfoWindow } = await google.maps.importLibrary("maps");
      const center = new google.maps.LatLng(latitude, longitude);

      // Initialize the map
      map = new Map(mapRef.current, {
        center: center,
        zoom: 11,
        mapId: "DEMO_MAP_ID",
      });

      // Perform nearby search
      nearbySearch(map);
    };

    const nearbySearch = async (map) => {
      const { Place, SearchNearbyRankPreference } =
        await google.maps.importLibrary("places");
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        "marker"
      );

      const request = {
        fields: [
          "displayName",
          "location",
          "photos",
          "userRatingCount",
          "reviews",
          "formattedAddress",
        ],
        locationRestriction: {
          center: new google.maps.LatLng(latitude, longitude),
          radius: 10000,
        },
        includedPrimaryTypes: [
          "tourist_attraction",
          "park",
          "aquarium",
          "hiking_area",
          "amusement_park",
          "art_gallery",
          "zoo",
          "museum",
          "performing_arts_theater",
          "historical_landmark",
          "church",
          "hindu_temple",
          "mosque",
          "synagogue",
        ],
        maxResultCount: 10,
        rankPreference: SearchNearbyRankPreference.POPULARITY,
        language: "en-US",
        region: "in",
      };

      const { places } = await Place.searchNearby(request);

      if (places.length) {
        const { LatLngBounds } = await google.maps.importLibrary("core");
        const bounds = new LatLngBounds();

        places.forEach((place) => {
          const markerView = new AdvancedMarkerElement({
            map,
            position: place.location,
            title: place.displayName,
          });

          bounds.extend(place.location);
        });

        map.fitBounds(bounds);
      } else {
        console.log("No results");
      }
      setPlaces(places);
    };

    initMap();
  }, [latitude, longitude, displayName, cityFacts]);

  console.log(places)

  return (
    <div>
      <h1 className="font-sans font-bold text-4xl p-2" id={displayName}>
        Places to visit near {displayName}
        <div>
          <p className="font-semibold text-sm md:text-base lg:text-lg p-4">
            {cityFacts}
          </p>
        </div>
      </h1>
      {/* <div><PlaceCheckBoxes/></div> */}

      <div
        className="shadow-2xl"
        id="map"
        ref={mapRef}
        style={{ width: "100%", height: "300px" }}
      ></div>
      <div className="m-1">
        <div className="m-1">
          {places
            .filter(
              (place) =>
                place?.userRatingCount >= 10 &&
                place?.photos?.length >= 1 &&
                !(place.reviews[4].text === null)
            )
            .map((place) => (
              <PlaceCard
                key={place?.id}
                displayName={place?.displayName}
                formattedAddress={place?.formattedAddress}
                lat={place?.location.lat()}
                lng={place?.location.lng()}
                userRatingCount={place?.userRatingCount}
                photoUrl={place.photos[0].getURI()}
                place={place}
                reviews={
                  place.reviews[0].text +
                  ". " +
                  place.reviews[1].text +
                  ". " +
                  place.reviews[2].text +
                  ". " +
                  place.reviews[3].text +
                  ". " +
                  place.reviews[4].text
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default GoogleMapComponent;
